import { DEFAULT_LANGUAGE } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('Asia/Jakarta');

export const PASSWORD_LINK_GENERATOR = 'sswedding';

/**
 * @/constants
 * for Brides information
 */
export const IS_BOY_FIRST = true; //change to false for switch broadcast text

/*Girl Profile*/
export const GIRL_NAME = 'Syierren Susanto, S.I.Kom.';
export const GIRL_NAME_SHORT = 'Syierren';
export const GIRL_FATHER_NAME = `Anthony Susanto`;
export const GIRL_MOTHER_NAME = `Lisnur Djunaidy`;

/*Boy Profile*/
export const BOY_NAME = 'Sandy Harsono Pantow, S.E., M.M., RFP';
export const BOY_NAME_SHORT = 'Sandy';
export const BOY_FATHER_NAME = `Tonny Harsono`;
export const BOY_MOTHER_NAME = `Anita Priyono (†)`;

export const GIRL_PARENT_NAME =
  DEFAULT_LANGUAGE === 'en'
    ? `The Second Daughter of <br/><b>Mr. ${GIRL_FATHER_NAME}</b> <br/> and <b>Mrs. ${GIRL_MOTHER_NAME}</b>`
    : `Perempuan yang lebih kerap disapa <b>${GIRL_NAME_SHORT}</b> ini adalah putri kedua dari pasangan <b>Bapak ${GIRL_FATHER_NAME}</b> dan <b>Ibu ${GIRL_MOTHER_NAME}</b>. Lahir di kota Semarang, kini ${GIRL_NAME_SHORT} telah menemukan labuhan hati terakhirnya, ${BOY_NAME_SHORT}.`;

export const BOY_PARENT_NAME =
  DEFAULT_LANGUAGE === 'en'
    ? `The First Son of <br/><b>Mr. ${BOY_FATHER_NAME}</b><br/> and <b>Mrs. ${BOY_MOTHER_NAME}</b>`
    : `<b>${BOY_NAME_SHORT}</b>, begitulah Ia disapa. Putra dari pasangan <b>Bapak ${BOY_FATHER_NAME}</b> dan <b>Ibu ${BOY_MOTHER_NAME}</b> ini lahir di kota Semarang. Setelah mengagumi ${GIRL_NAME_SHORT} sejak lama, akhirnya kini Ia berhasil mendapatkan hati ${GIRL_NAME_SHORT} untuk selamanya.`;

export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'hellosanpan';
export const IG_GIRL = 'syierrensusanto';

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/farahluthfioktarina/sandysyierren/seo.jpg?updatedAt=1709266044031`;
export const SEO_URL = 'https://invitato.id/sandysyierren';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato.id`;
export const SEO_DESCRIPTION =
  DEFAULT_LANGUAGE === 'en'
    ? `Together with joyful hearts, we are pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details:`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1713585600;
export const EPOCH_RESEPSI_START = 1713610800;
export const EPOCH_END_EVENT = 1713621600;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = dayjs.tz('2024-04-20');
export const DATE_RESEPSI = dayjs.tz('2024-04-20');

export const WEDDING_DATE_DAY = dayjs(DATE_AKAD).format('dddd');
export const WEDDING_DATE_DATE = dayjs(DATE_AKAD).format('DD MMMM YYYY');
export const WEDDING_AKAD = dayjs(DATE_AKAD).locale(DEFAULT_LANGUAGE).format('dddd, DD MMMM YYYY');
export const WEDDING_AKAD_TIME = '11.00 WIB';
export const WEDDING_LOCATION_AKAD = 'Lucerne Room, 7th Floor. Swissotel Jakarta PIK Avenue';
export const WEDDING_LOCATION_AKAD_ROAD = `Jalan Pantai Indah Kapuk, RT 6/RW 2, Kamal Muara, Penjaringan, North Jakarta 14470`;
export const WEDDING_LOCATION_AKAD_ROAD_SIMPLE = ``;

export const WEDDING_RESEPSI_DAY = dayjs(DATE_RESEPSI).format('dddd');
export const WEDDING_RESEPSI = WEDDING_DATE_DATE;
export const WEDDING_RESEPSI_TIME = '18.00 WIB';
export const WEDDING_LOCATION = `Geneva Ballroom, 7th Floor. Swissotel Jakarta PIK Avenue`;
export const WEDDING_LOCATION_ROAD = `Jalan Pantai Indah Kapuk, RT 6/RW 2, Kamal Muara, Penjaringan, North Jakarta 14470`;
export const WEDDING_RESEPSI_DRESSCODE = '';
export const WEDDING_RESEPSI_CITY = '';
export const WEDDING_DATE = dayjs(DATE_RESEPSI).format('DD • MM • YYYY');

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://sandysyierren.com/';
export const BROADCAST_WEDDING_LOCATION = WEDDING_LOCATION;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_LINK = `https://maps.app.goo.gl/ZtBae4hTpu6NMZiK9`;
export const GOOGLE_MAPS_ADDRESS = WEDDING_LOCATION;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `Jake Miller - Lucky Me (The proposal)`;
export const SOUND_URL = 'https://www.youtube.com/watch?v=XysLGnlSjE0';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://bit.ly/RAIofSENshine';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/LNhRG43cpGE';
export const PREWEDDING_LINK = 'https://www.youtube.com/watch?v=Hxw5K3erbw4';
export const PREWEDDING_EMBED = 'https://www.youtube.com/embed/Hxw5K3erbw4?si=om0fLfDXMvF6hv-i';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const URL_INVITATO = 'https://invitato.net';
export const URL_IG_INVITATO = 'https://www.instagram.com/invitato.id/';
export const URL_WA_INVITATO = `https://wa.me/+6281329274582?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!`;
export const INVITATO_URL = `https://invitato.id`;
